var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "", loading: _vm.fetchingDropboxState } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "secondary--text font-weight-bold" }, [
          _vm._v("Dropbox sync"),
        ]),
      ]),
      _c("v-card-subtitle", [
        _c(
          "a",
          {
            staticStyle: { "text-decoration": "none" },
            attrs: {
              href: "https://docs.nuvolos.cloud/features/file-system-and-storage/mount-dropbox",
              target: "_blank",
            },
          },
          [_vm._v(" How does this work? ")]
        ),
      ]),
      _c(
        "v-card-text",
        [
          _vm.fetchingDropboxState
            ? _c("div", [
                _c("p", [
                  _vm._v("Checking your Dropbox configuration, please wait..."),
                ]),
              ])
            : !_vm.isDropboxLinked
            ? _c(
                "div",
                [
                  _c("img", {
                    staticClass: "mb-2",
                    attrs: {
                      src: require("@/assets/Dropbox.svg"),
                      width: "100",
                    },
                  }),
                  _c("p", [
                    _vm._v(
                      "To enable integration in Nuvolos, give access to the nuvolos.cloud application:"
                    ),
                  ]),
                  _c(
                    "v-btn",
                    { attrs: { href: _vm.redirectURI, color: "primary" } },
                    [_c("b", [_vm._v("Enable access")])]
                  ),
                ],
                1
              )
            : _c(
                "v-alert",
                {
                  staticClass: "mt-4",
                  attrs: { type: "success", prominent: "" },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow" }, [
                        _vm._v(
                          "You have enabled access to your Dropbox account for nuvolos.cloud."
                        ),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.unlinkDropbox()
                                },
                              },
                            },
                            [_vm._v("Unlink")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }